<template>
  <div>
    <Bread BreadTitle="廣論"></Bread>
    <div class="row">
      <div class="col-md-4 col-8 mb-1">
        <div class="input-group">
          <span class="input-group-text">廣論頁數</span>
          <input
            type="text"
            id="tbxPageNum"
            class="form-control"
            v-model="basedata.PageNum"
          />
        </div>
      </div>
      <div class="col-md-2 col-4">
        <div class="input-group">
          <button class="btn btn-primary btn-sm multi-btn" @click="DoLarmRead">
            閱讀
          </button>
          <button
            class="btn btn-success btn-sm multi-btn"
            @click="BackupRecord"
            v-if="basedata.vTargetURL != ''"
          >
            複製紀錄
          </button>
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6 mb-1">
        <div class="input-group">
          <input
            type="text"
            v-model="basedata.jsonstrload"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6 mb-1">
        <div class="input-group">
          <button class="btn btn-success btn-sm" @click="LoadRecord">
            匯入紀錄
          </button>
        </div>
      </div>

      <div class="col-md-4 col-12 mb-1">
        <div class="input-group">
          <span class="input-group-text">【手抄】冊</span>
          <input
            type="text"
            id="tbxBook"
            class="form-control"
            v-model="basedata.vBook"
          />
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-12 mb-1">
        <div class="input-group">
          <span class="input-group-text">AB</span>
          <div class="input-group-append form-control">
            <label
              ><input
                type="radio"
                name="optradio"
                value="A"
                v-model="basedata.vAB"
              />A</label
            >
            <label
              ><input
                type="radio"
                name="optradio"
                value="B"
                v-model="basedata.vAB"
              />B</label
            >
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-12">
        <div class="input-group">
          <input
            type="button"
            value="閱讀"
            id="btnRead"
            class="btn btn-primary multi-btn btn-sm"
            @click="DoDailyRead"
          />
          <input
            type="button"
            value="播放"
            id="btnLamAdd"
            class="btn btn-primary multi-btn btn-sm"
            @click="DoLamAdd"
          />
        </div>
      </div>
      <!--
      <div class="col-md-8 col-sm-12">
        <audio id="MyAudio" controls="" class="form-control input-lg">
          <source id="MySource" src="" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <div class="col-md-4 col-sm-8">
        <div class="input-group">
          <input
            type="button"
            value="記憶"
            id="btnLamAdd"
            class="btn btn-danger multi-btn btn-sm"
            @click="DoLamAdd"
          />
        </div>
      </div> -->
      <div class="col-md-12 col-sm-12">
        <AudiPlay :PlayURL="basedata.vTargetURL" ref="compAPlay" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";
import AudiPlay from "../components/AudiPlay.vue";
import Bread from "../components/Bread.vue";
import myBackup from "@/composables/Record/Backup";
import myLoad from "@/composables/Record/Load";
export default {
  name: "Larm",
  components: {
    AudiPlay,
    Bread,
  },
  setup() {
    const divRef = ref(AudiPlay);
    const basedata = reactive({
      list: [],
      arrayRecordTime: new Array(),
      vBook: "",
      vAB: "A",
      vTargetURL: "",
      PageNum: "",
      jsonstrload: "",
    });

    function BackupRecord() {
      //Call Composition API :Record/Backup.js
      myBackup(basedata);
    }

    function LoadRecord() {
      //Call Composition API :Record/Load.js
      myLoad(basedata);
    }

    function right(str, num) {
      return str.substring(str.length - num, str.length);
    }

    function DoLamAdd() {
      //將 arrayRecordTime 陣列清空
      basedata.arrayRecordTime = [];

      console.log("vBook", basedata.vBook);
      console.log("vAB", basedata.vAB);

      if (basedata.vBook == "") {
        alert("請先輸入第幾冊!");
        return false;
      }

      if (parseInt(basedata.vBook) < 100) {
        var vBookTmp = "00" + basedata.vBook.toString();
        basedata.vBook = right(vBookTmp, 3);
      }

      basedata.vTargetURL =
        "https://theqi.com/buddhism/GL1/audio/" +
        basedata.vBook +
        basedata.vAB.toUpperCase() +
        ".MP3";

      //DoPaly();

      divRef.value.PlayURL = basedata.vTargetURL;
      console.log("ddddd", divRef.value);
      divRef.value.methods.DoPlay(basedata.vTargetURL);
    }

    function DoDailyRead() {
      if (basedata.vBook == "") {
        alert("請先輸入第幾冊!");
        return false;
      }

      if (parseInt(basedata.vBook) < 100) {
        var vBookTmp = "00" + basedata.vBook.toString();
        basedata.vBook = right(vBookTmp, 2);
      }
      var vLink =
        "https://theqi.com/buddhism/GL1/data/s" +
        basedata.vBook +
        basedata.vAB.toLowerCase() +
        ".html";
      window.open(vLink, "_blank");
    }

    function DoPaly() {
      var audio = document.getElementById("MyAudio");
      var source = document.getElementById("MySource");

      //如果音檔來源空白，停止進行
      if (basedata.vTargetURL == "") {
        alert("請先輸入音檔來源!");
        return false;
      }

      source.src = basedata.vTargetURL;

      audio.load();
      audio.play();
    }

    function DoLarmRead() {
      var vUserInput = basedata.PageNum;
      if (isNaN(vUserInput)) {
        alert("請輸入正確的頁數");
        return;
      }

      var vPage = "larmbook.html?num=" + vUserInput;

      window.open(vPage, "_blank");
    }

    onMounted(() => {
      divRef.value.PlayURL = "";

      var vRecordLocalStoragelarmBook = localStorage.getItem("larmBook");
      if (vRecordLocalStoragelarmBook == null) vRecordLocalStoragelarmBook = "";
      basedata.vBook = vRecordLocalStoragelarmBook;

      var vRecordLocalStoragelarmBookAB = localStorage.getItem("larmBookAB");
      if (vRecordLocalStoragelarmBookAB == null)
        vRecordLocalStoragelarmBookAB = "A";
      basedata.vAB = vRecordLocalStoragelarmBookAB;

      var vRecordLocalStoragelarmPageNum = localStorage.getItem("larmPageNum");
      if (vRecordLocalStoragelarmPageNum == null)
        vRecordLocalStoragelarmPageNum = "";
      basedata.PageNum = vRecordLocalStoragelarmPageNum;
    });

    watch(
      () => basedata.vBook,
      (vNew, vOld) => {
        console.log("basedata.vBook-vNew", vNew);
        console.log("basedata.vBook-vOld", vOld);

        //存 local storage
        localStorage.setItem("larmBook", vNew);
      }
    );

    watch(
      () => basedata.vAB,
      (vNew, vOld) => {
        console.log("basedata.vAB-vNew", vNew);
        console.log("basedata.vAB-vOld", vOld);

        //存 local storage
        localStorage.setItem("larmBookAB", vNew);
      }
    );

    watch(
      () => basedata.PageNum,
      (vNew, vOld) => {
        console.log("basedata.PageNum-vNew", vNew);
        console.log("basedata.PageNum-vOld", vOld);

        //存 local storage
        localStorage.setItem("larmPageNum", vNew);
      }
    );

    return {
      DoLamAdd,
      DoDailyRead,
      basedata,
      DoPaly,
      divRef,
      DoLarmRead,
      BackupRecord,
      LoadRecord,
    };
  },
  methods: {
    SetUrl() {
      return this.basedata.vTargetURL;
    },
  },
};
</script>

<style>
.form-control label {
  padding-right: 20px;
}

.multi-btn {
  margin: 0.5em 0.5em;
}
</style>